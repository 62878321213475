import HelpIcon from "@mui/icons-material/Help";
import {
  Card,
  CardContent,
  Checkbox,
  Chip,
  Container,
  Divider,
  FormControlLabel,
  FormGroup,
  Pagination,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const IconWithTooltip = ({ title }) => (
  <Tooltip
    title={title}
    sx={{ marginLeft: "0.5rem", fontSize: "1rem", color: "#888" }}
  >
    <HelpIcon />
  </Tooltip>
);

const pageConfigs = [
  {
    question: "Do you have a composter?",
    form: (
      <RadioGroup
        aria-labelledby='demo-radio-buttons-group-label'
        defaultValue='female'
        name='radio-buttons-group'
        row
      >
        <FormControlLabel
          value='yes'
          control={<Radio />}
          label='Yes'
          labelPlacement='top'
        />
        <FormControlLabel
          value='no'
          control={<Radio />}
          label='No'
          labelPlacement='top'
        />
      </RadioGroup>
    ),
  },
  {
    question: "Select the items which you compost:",
    form: (
      <div class='form-wrapper'>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox />}
            label={
              <div>
                Kitchen waste
                <IconWithTooltip
                  title='kitchen waste, for instance peelings, leftover vegetables and fruit, eggshells, coffee/tea
grounds, stale bread'
                />
              </div>
            }
          />
          <FormControlLabel
            control={<Checkbox />}
            label={
              <div>
                Plants
                <IconWithTooltip title='leaves, sprouts, plant stems' />
              </div>
            }
          />
          <FormControlLabel control={<Checkbox />} label='Cut grass' />
          <FormControlLabel
            control={<Checkbox />}
            label={
              <div>
                Tree, hay
                <IconWithTooltip title='tree bark and fine branches, hay' />
              </div>
            }
          />
          <FormControlLabel
            control={<Checkbox />}
            label={
              <div>
                Pot plants
                <IconWithTooltip title='withered flowers and pot plants' />
              </div>
            }
          />
          <FormControlLabel
            control={<Checkbox />}
            label={
              <div>
                Paper
                <IconWithTooltip title='unprinted and soft paper/cardboard (i.e. paper for wrapping food, tissues, paper towels)' />
              </div>
            }
          />
        </FormGroup>
        <aside>
          <h4>Things that should not be placed in a composter</h4>
          <ul>
            <li>
              materials such as ceramics, plastics, metals, glass or fabric –
              they are not composted
            </li>
            <li>
              organic matter which may contain chemicals, such as printed paper
              or newspapers, empty cardboard beverage packagings, painted and
              impregnated wood,
            </li>
            <li>
              kitchen waste such as meat, bones, fat, dairy or whole eggs which
              generate unpleasant odour when decomposing
            </li>
            <li>animal faeces, cat litter (for sanitary reasons)</li>
            <li>spoiled food</li>
            <li>soil, gravel, stones</li>
            <li>
              parts of diseased plants or plants attacked by parasites (mushroom
              spores or parasitic eggs can survive the composting process and be
              reintroduced to the soil),
            </li>
            <li>
              weeds which have created seeds (the seeds are going to survive in
              the compost and sprout in soil covered in compost)
            </li>
            <li>
              thick branches and pine needles – they decompose for a long time,
              extending the time needed for generating compost
            </li>
            <li>
              plant-based waste which may be contaminated, such as weed with
              plant protection products or peelings from citrus fruits which
              contain preservatives
            </li>
          </ul>
        </aside>
      </div>
    ),
  },
  {
    question: "Provide the following information:",
    form: (
      <div>
        <Divider>
          <Chip label='Kitchen waste' />
        </Divider>
        <p>
          How many liters of biodegradable rubbish do you accumulate per week?
        </p>
        <TextField
          fullWidth
          inputProps={{
            type: "number",
            min: 0,
            inputMode: "numeric",
            pattern: "[0-9]*",
          }}
        />
        <Divider>
          <Chip label='Plants' />
        </Divider>
        <p>How long does it take you using shredder for branches per season?</p>
        <TextField
          fullWidth
          inputProps={{
            type: "number",
            min: 0,
            inputMode: "numeric",
            pattern: "[0-9]*",
          }}
        />
        <Divider>
          <Chip label='Cut grass' />
        </Divider>
        <p>What is the area of your garden plot?</p>
        <TextField
          fullWidth
          inputProps={{
            type: "number",
            min: 0,
            inputMode: "numeric",
            pattern: "[0-9]*",
          }}
        />
        <p>How often do you cut grass per month?</p>
        <TextField
          fullWidth
          inputProps={{
            type: "number",
            min: 0,
            inputMode: "numeric",
            pattern: "[0-9]*",
          }}
        />
        <p>Provide percentage of composting in this category</p>
        <TextField
          fullWidth
          inputProps={{
            type: "number",
            min: 0,
            max: 100,
            inputMode: "numeric",
            pattern: "[0-9]*",
          }}
        />
      </div>
    ),
  },
  {
    question: "Provide the following information:",
    form: (
      <div>
        <Divider>
          <Chip label='Tree, hay' />
        </Divider>
        <p>
          How much waste do you produce in this category per season in liters?
        </p>
        <TextField
          fullWidth
          inputProps={{
            type: "number",
            min: 0,
            inputMode: "numeric",
            pattern: "[0-9]*",
          }}
        />
        <p>Provide percentage of composting in this category</p>
        <TextField
          fullWidth
          inputProps={{
            type: "number",
            min: 0,
            max: 100,
            inputMode: "numeric",
            pattern: "[0-9]*",
          }}
        />
        <Divider>
          <Chip label='Pot plants' />
        </Divider>
        <p>
          How much waste do you produce in this category per season in liters?
        </p>
        <TextField
          fullWidth
          inputProps={{
            type: "number",
            min: 0,
            inputMode: "numeric",
            pattern: "[0-9]*",
          }}
        />
        <p>Provide percentage of composting in this category</p>
        <TextField
          fullWidth
          inputProps={{
            type: "number",
            min: 0,
            max: 100,
            inputMode: "numeric",
            pattern: "[0-9]*",
          }}
        />
        <Divider>
          <Chip label='Paper' />
        </Divider>
        <p>How many kilograms of paper waste do you produce per month?</p>
        <TextField
          fullWidth
          inputProps={{
            type: "number",
            min: 0,
            inputMode: "numeric",
            pattern: "[0-9]*",
          }}
        />
        <p>Provide percentage of composting in this category</p>
        <TextField
          fullWidth
          inputProps={{
            type: "number",
            min: 0,
            max: 100,
            inputMode: "numeric",
            pattern: "[0-9]*",
          }}
        />
      </div>
    ),
  },
];

const Survey = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [question, setQuestion] = useState(pageConfigs[page - 1].question);
  const [form, setForm] = useState(pageConfigs[page - 1].form);

  useEffect(() => {
    const config = pageConfigs[page - 1];
    setQuestion(config.question);
    setForm(config.form);
  }, [page]);

  return (
    <div id='survey'>
      <Container>
        <Card>
          <CardContent>
            <h1>{question}</h1>
            <div className='form'>{form}</div>
          </CardContent>
          <Stack spacing={2}>
            <Pagination
              className='pagination'
              count={pageConfigs.length}
              page={page}
              onChange={(_e, value) => setPage(value)}
            />
          </Stack>
        </Card>
        {page === pageConfigs.length ? (
          <button onClick={() => navigate("/summary")} className='navigation'>
            Check your composting data
          </button>
        ) : (
          ""
        )}
      </Container>
    </div>
  );
};

export default Survey;
