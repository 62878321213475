import CardGiftcardTwoToneIcon from "@mui/icons-material/CardGiftcardTwoTone";
import CastForEducationTwoToneIcon from "@mui/icons-material/CastForEducationTwoTone";
import SchoolTwoToneIcon from "@mui/icons-material/SchoolTwoTone";
import { Card, CardContent, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { faker } from "@faker-js/faker";

const Home = () => {
  const navigate = useNavigate();

  return (
    <div>
      <header>
        <h1 class='head'>
          <span>Let's compost IT!</span>
        </h1>
        <h2>
          <span>Take part in the survey and see what you can gain</span>
        </h2>
      </header>
      <main>
        <Container>
          <div className='home__cards'>
            <Card>
              <CardContent>
                <h2>Knowledge</h2>
                <SchoolTwoToneIcon
                  sx={{ fontSize: "4.5rem", margin: "3rem" }}
                />
                <p>You will get an insight into your waste management habits</p>
              </CardContent>
            </Card>
            <Card>
              <CardContent>
                <h2>Coupon</h2>
                <CardGiftcardTwoToneIcon
                  sx={{ fontSize: "4.5rem", margin: "3rem" }}
                />
                <p>Take part in the drawing of a coupon for a new composter</p>
              </CardContent>
            </Card>
            <Card>
              <CardContent>
                <h2>Course</h2>
                <CastForEducationTwoToneIcon
                  sx={{ fontSize: "4.5rem", margin: "3rem" }}
                />
                <p>Join our effective composting training courses</p>
              </CardContent>
            </Card>
          </div>
          <div className='testimonials'>
            <div>
              <div className='avatar'>
                <img src={faker.image.avatar()} alt='' />
              </div>
              <p>
                We are very happy to see every kilogram of bio-waste being
                composted! To make it easier for you, you'll get a chance to win
                a professional composter after filling the survey, just like{" "}
                <b>Kevin</b>!
              </p>
            </div>
            <div>
              <div className='avatar'>
                <img src={faker.image.avatar()} alt='' />
              </div>
              <p>
                Our tutors conduct professional courses in every voivodeship in
                Poland about composting. Don't wait, just fill the form, you can
                be next!
              </p>
            </div>
          </div>
          <button onClick={() => navigate("/survey")} className='navigation'>
            Fill out the survey
          </button>
        </Container>
      </main>
    </div>
  );
};

export default Home;
