import { Card, Paper } from "@mui/material";
import { Container } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import Plot from "react-plotly.js";

const Summary = () => {
  const [compostAmount, setCompostAmount] = useState(0);
  const [compostCost, setCompostCost] = useState(0);
  const [moneySaved, setMoneySaved] = useState(0);
  const congratulations = useRef();
  const [showCongratulations, setShowCongratulations] = useState(true);

  useEffect(() => {
    const max = 1307;
    const increaseCompostAmount = setTimeout(() => {
      setCompostAmount(compostAmount + max / 250);
    }, 1);
    if (compostAmount >= max) {
      setCompostAmount(max);
      clearTimeout(increaseCompostAmount);
    }
  }, [compostAmount]);

  useEffect(() => {
    const max = 1533;
    const increaseCompostCost = setTimeout(() => {
      setCompostCost(compostCost + max / 250);
    }, 1);
    if (compostCost > max) {
      setCompostCost(max);
      clearTimeout(increaseCompostCost);
    }
  }, [compostCost]);

  useEffect(() => {
    const max = 912;
    const increaseMoneySaved = setTimeout(() => {
      setMoneySaved(moneySaved + max / 250);
    }, 1);
    if (moneySaved > max) {
      setMoneySaved(max);
      clearTimeout(increaseMoneySaved);
    }
  }, [moneySaved]);

  return (
    <div>
      {showCongratulations ? (
        <div ref={congratulations} id='congratulations'>
          <h2>Congratulations!</h2>
          <h3>
            You have won a coupon for a composter! Click{" "}
            <a href='# ' onClick={() => setShowCongratulations(false)}>
              here
            </a>{" "}
            to claim your reward.
          </h3>
        </div>
      ) : (
        ""
      )}
      <div id='summary'>
        <div className='fluid'>Yearly data</div>
        <Container>
          <div class='stats'>
            <Card>
              <span className='stat'>{Math.floor(compostAmount)}</span>
              <p>kg</p>
              <p>of compost produced</p>
            </Card>
            <Card>
              <span className='stat'>{Math.floor(compostCost)}</span>
              <p>PLN</p>
              <p> of compost market value</p>
            </Card>
            <Card>
              <span className='stat'>{Math.floor(moneySaved)}</span>
              <p>PLN</p>
              <p>saved</p>
            </Card>
          </div>
          <Paper className='plots'>
            <Plot
              data={[
                {
                  x: [2020, 2021, 2022],
                  y: [1301, 1387, 1533],
                  type: "scatter",
                  mode: "lines+markers",
                  marker: { color: "hsl(124, 100%, 28%)" },
                  line: { shape: "spline" },
                  name: "Compost market value",
                },
                {
                  type: "bar",
                  x: [2020, 2021, 2022],
                  y: [1000, 1125, 912],
                  marker: { color: "hsl(42, 74%, 45%)" },
                  name: "Money saved",
                },
              ]}
              layout={{
                title: "Comparison over the recent years",
                width: "80%",
                font: {
                  family: "Roboto Slab",
                },
                legend: { orientation: "h" },
                xaxis: { tickmode: "array", tickvals: [2020, 2021, 2022] },
              }}
              config={{ displayModeBar: false }}
            />
          </Paper>
        </Container>
      </div>
    </div>
  );
};

export default Summary;
